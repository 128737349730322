export default {
	
	// 开发api请求地址，小程序必须是https
	devUrl: 'http://localhost:8080/api',
	
	// 生产api请求地址，小程序必须是https
	proUrl: '/api',
	
	//调试模式
	debug: false,

	mapKey:"5QMBZ-XVC3U-CLNVG-4L6JG-HDVT5-NKBLW",

	configId: 110,
	// 公钥
	publicKey: ''
}